import React from 'react';
import Responsive from 'react-responsive';

const Desktop = props => <Responsive {...props} minWidth={900} />;
const Tablet = props => <Responsive {...props} minWidth={600} maxWidth={899} />;
const Mobile = props => <Responsive {...props} maxWidth={599} />;

export default (Component) => {
  const Home = () => (
    <>
      <Mobile>
        <Component isMobile />
      </Mobile>
      <Tablet>
        <Component isMobile={false} isTablet />
      </Tablet>
      <Desktop>
        <Component isMobile={false} isTablet={false} />
      </Desktop>
    </>
  );

  return Home;
};
